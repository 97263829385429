<template>
  <vx-card>
    <vs-row>
      <h4 class="mb-5">Add Partner</h4>
    </vs-row>

    <vs-row vs-w="12">
      <vs-col vs-w="6">
        <vs-row vs-w="12">

          <vs-col vs-w="12" class="mb-3">
            <vs-input
              class="w-full"
              label="Partner Name (*)"
              v-model="payload.name"
            />
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("name")
            }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mb-3">
            <span class="mb-2 block text-sm">Category (*)</span>
            <v-select
              label="name"
              :options="categories"
              :reduce="(type) => type.id"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="payload.category_id"
            />
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("category")
            }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mt-3">
            <div class="vx-col w-full" @click.prevent="storeData">
              <vs-button class="mr-3 mb-2">Submit</vs-button>
            </div>
          </vs-col>
        </vs-row>
      </vs-col>

      <vs-col vs-w="6" class="pl-10 pt-6">
        <div class="flex items-start flex-col sm:flex-row">
          <img :src="url" class="rounded mr-4" width="150" height="150" />
          <div>
            <input
              type="file"
              @change="addImage"
              class="hidden"
              ref="update_logo_input"
              accept="image/*"
            />
            <vs-button
              @click="$refs.update_logo_input.click()"
              class="mr-4 mb-2"
              type="filled"
              icon-pack="feather"
              icon="icon-edit"
            ></vs-button>
            <vs-button
              @click="removeImage"
              type="border"
              color="danger"
              icon-pack="feather"
              icon="icon-trash"
            ></vs-button>
          </div>
        </div>
        <span class="block text-sm mt-2 text-danger">{{
          errors.first("logo")
        }}</span>
      </vs-col>
    </vs-row>
  </vx-card>
</template>

<script>
import vSelect from "vue-select";
import { mapGetters, mapActions } from "vuex";

// Store Module
import modulePartnerManagement from "@/store/partner-management/modulePartnerManagement.js";
import moduleCategoryProductManagement from "@/store/category-product-management/moduleCategoryProductManagement.js";

export default {
  name: "PartnerAdd",

  metaInfo: {
    title: "Add Partner",
    titleTemplate: "%s - Vancy Net",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },

  components: {
    "v-select": vSelect,
  },

  watch: {
    url(url) {
      if (url == null) {
        this.url = "https://via.placeholder.com/150";
      } else {
        this.url = url;
      }
    },
  },

  data: () => ({
    partnerTypes: [
      { name: "Main", val: "main" },
      { name: "Payment", val: "payment" },
      { name: "Other", val: "other" },
    ],

    payload: {
      name: "",
      type: "",
      logo: "",
      category_id: "",
    },

    url: null,
  }),

  computed: {
    categories() {
      return this.$store.state.categoryProduct.category_products;
    },
  },

  methods: {
    addImage(e) {
      const file = e.target.files[0];
      this.url = URL.createObjectURL(file);
      this.payload.logo = file;
    },

    removeImage() {
      this.url = null;
      this.payload.logo = "";
    },

    storeData() {
      const formData = new FormData();
      // set formData
      formData.set("name", this.payload.name);
      formData.set("category_product_id", this.payload.category_id);
      if (this.payload.logo !== "") {
        formData.set("logo", this.payload.logo);
      }

      this.$store
        .dispatch("partnerManagement/storePartner", formData)
        .then(({ data: res }) => {
          this.$vs.notify({
            title: "Successful",
            text: res.msg_status,
            iconPack: "feather",
            icon: "icon-check-circle",
            color: "success",
          });

          this.$router.push({ name: "partners" });
        })
        .catch((err) => {
          this.$catchErrorResponse(err);
        });
    },
  },

  async mounted() {
    if (this.url == null) {
      this.url = "https://via.placeholder.com/150";
    }
    await this.$store.dispatch("categoryProduct/fetchCategoryProducts");
  },

  created() {
    if (!modulePartnerManagement.isRegistered) {
      this.$store.registerModule("partnerManagement", modulePartnerManagement);
      modulePartnerManagement.isRegistered = true;
    }
    if (!moduleCategoryProductManagement.isRegistered) {
      this.$store.registerModule(
        "categoryProduct",
        moduleCategoryProductManagement
      );
      modulePartnerManagement.isRegistered = true;
    }
  },
};
</script>

<style></style>
