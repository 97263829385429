import state from './moduleCategoryProductManagementState.js'
import mutations from './moduleCategoryProductManagementMutations.js'
import actions from './moduleCategoryProductManagementActions.js'
import getters from './moduleCategoryProductManagementGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

