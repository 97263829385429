import axios from '@/axios.js'

export default {
  fetchCategoryProducts ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/property-types')
        .then(({data: response}) => {
          commit('SET_CATEGORY_PRODUCTS', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchCategoryProduct (context, categoryProductId) {
    return new Promise((resolve, reject) => {
      axios.get(`/property-types/${categoryProductId}`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },

  storeCategoryProduct (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/property-types', payload)
           .then(res => {
             resolve(res.data)
           })
           .catch(err => {
             reject(err)
           })
    })
  },

  updateCategoryProduct (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/property-types/${payload.id}`, payload.body)
           .then(res => {
             resolve(res)
           })
           .catch(err => {
             reject(err)
           })
    })
  },

  destroyCategoryProduct ({ commit }, categoryProductId) {
    return new Promise((resolve, reject) => {
      axios.delete(`category-product/${categoryProductId}`)
        .then((response) => {
          commit('REMOVE_RECORD', categoryProductId)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
