import state from './modulePartnerManagementState.js'
import mutations from './modulePartnerManagementMutations.js'
import actions from './modulePartnerManagementActions.js'
import getters from './modulePartnerManagementGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

