import axios from '@/axios.js'

export default {
  fetchPartners ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/partner')
        .then(({data: response}) => {
          commit('SET_PARTNERS', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchPartner (context, partnerId) {
    return new Promise((resolve, reject) => {
      axios.get(`/partner/${partnerId}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  storePartner (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/partner', payload)
           .then(res => {
             resolve(res)
           })
           .catch(err => {
             reject(err)
           })
    })
  },

  updatePartner (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`/partner/${payload.id}`, payload.body)
           .then(res => {
             resolve(res)
           })
           .catch(err => {
             reject(err)
           })
    })
  },

  destroyPartner ({ commit }, partnerId) {
    return new Promise((resolve, reject) => {
      axios.delete(`partner/${partnerId}`)
        .then((response) => {
          commit('REMOVE_RECORD', partnerId)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
